.subnet-view .description {
    vertical-align: middle;
    text-align: right;
}

.subnet-view td {
    padding-right: 15px;
}

.subnet-view {
    margin-bottom: 20px;
}

.subnet-view .part {
    border-bottom: solid 1px;
}

.subnet-view button {
    margin:0 3px;
}

.subnet-view .ip-address-col { min-width: 8.5em;}