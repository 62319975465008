.tooltip-holder {
    position: relative;
    display: inline
}

.tooltip-holder .tooltip-icon {
    opacity: 0.5;
    z-index: 0;
    vertical-align: middle;
}

.tooltip-holder .tooltip {
    display: none;
    font-size: 0.9em;
    position: absolute;
    margin-left: 5px;
    width: 300px;
    padding: 10px;
    border-radius: 5px;
    z-index: 10;
}

.tooltip-holder .tooltip p {
    margin-bottom: 0;
}

.tooltip-holder .tooltip .tooltip-header {
    font-weight: bold;
    margin-bottom: 5px;
}

.tooltip:hover {
    display: inline;
}

.tooltip-holder:hover .tooltip {
    display: inline;
}