.help ul { list-style-type: none; margin: 0; margin-left: 10px; margin-top: 0.2em; padding: 0;  }
.help li { padding: 1px; margin-bottom: 5px;}
.help code, .help code a { color:mediumseagreen}
.light .help code, .light .help code a { color:green}
.help p { margin-top: 0.5em }
.help .section {padding: 1em;}
.help .panel-container {overflow: hidden;}
.help .left-panel {float:left; margin-right: 1em;}
.help .right-panel {float:left; }
.help .section-title {font-weight: bold; font-size: 1.1em;}
.help .important-note {padding: 5px; display: inline-block; margin-top: 5px;}
@media (min-width: 1024px) {
    .left-panel, .right-panel {
        width: 45%;
    }
}